
import {Component, Vue} from 'vue-property-decorator';
import {services} from '@/main';

@Component({})
export default class Diagnostic extends Vue {
  private callState: string = 'chargement ...';
  private classBadgeSecondary = 'badge-primary';
  private isLoading: boolean = true;

  private created() {
    services.restService.callPost('/api/v1/authentication/logout')
        .then((resp) => {
          this.callState = 'OK';
          this.classBadgeSecondary = 'badge-success';
        })
        .catch((respError) => {
          this.callState = 'KO';
          this.classBadgeSecondary = 'badge-danger';
        })
        .finally(() => {
          this.isLoading = false;
        });
  }
}
